<template>
    <div class="mb-4">
        <div class="text-gray-400 title text-left duration-500 pc_show">我的收藏</div>
        <div class="tabs flex items-center mt-4 pc_flex_show">
            <div
                v-for="(item,index) in tabs"
                :key="index"
                :class="tabIndex == index ? 'text-primary' : ''"
                class="mr-8 cursor-pointer font-bold"
                @click="changeTab(index)"
            >{{ item }}</div>
        </div>
        <div class="sm:mt-4 box_margin duration-500" v-show="tabIndex == 0 || tabIndex == 1">
            <div class="table_title duration-500 flex justify-between items-center w-full">
                <div>内容</div>
                <!-- <div class="text-gray-400">全部取消</div> -->
            </div>
            <div class="border_box conent_box flex items-center flex-wrap duration-500">
                <div v-if="getCollection.content.length > 0" class="w-full">
                    <ul class="w-full">
                        <li
                            v-for="item in getCollection.content"
                            :key="item.tidingsId"
                            class="flex justify-between items-center w-full"
                        >
                            <div>{{ item.tidingsName }}</div>
                            <div
                                class="text-gray-400 cursor-pointer'"
                                @click="cannelUserCollection('content', item)"
                            >取消收藏</div>
                        </li>
                    </ul>
                </div>
                <div
                    v-else
                    class="w-full flex justify-center items-center text-gray-500 null"
                >暂无收藏内容</div>
            </div>
        </div>
        <div class="sm:mt-4 box_margin duration-500" v-show="tabIndex == 0 || tabIndex == 2">
            <div class="table_title duration-500 flex justify-between items-center w-full">
                <div>壁纸</div>
            </div>
            <div class="border_box conent_box flex items-center flex-wrap duration-500">
                <div v-if="getCollection.wallpaper.length > 0" class="flex items-center flex-wrap">
                    <div
                        style="width:33.33%"
                        class="flex justify-center items-center"
                        v-for="(item,index) in getCollection.wallpaper"
                        :class="index > 2 ? 'mt-3' : ''"
                        :key="item.wallpaperId"
                    >
                        <div style="width:95%">
                            <div class="w-full relative">
                                <!-- <img src="../../../../assets/images/personal/personal4.png" alt /> -->
                                <img :src="item.image" alt @click="review(item.image)" />
                                <div class="absolute z-10 icon_box">
                                    <div class="flex items-center cursor-pointer phone_flex_show">
                                        <img
                                            src="../../../../assets/images/serve/collection.png"
                                            alt
                                            @click="cannelUserCollection('wallpaper', item)"
                                        />
                                        <img
                                            src="../../../../assets/images/serve/download.png"
                                            @click="download(item.downloadLink)"
                                            alt
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="flex justify-between mt-2 flex-wrap pc_flex_show">
                                <el-button
                                    class="btn"
                                    @click="cannelUserCollection('wallpaper', item)"
                                >取消收藏</el-button>
                                <el-button
                                    type="primary"
                                    class="btn download"
                                    @click="download(item.downloadLink)"
                                >下载壁纸</el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="w-full flex justify-center items-center text-gray-500 null"
                >暂无收藏壁纸</div>
            </div>
        </div>
        <Popup :isShow="isShow" @close="closeSearch">
            <template>
                <div>
                    <img :src="reviewImg" alt />
                </div>
            </template>
        </Popup>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Popup from "@/components/Popup/Popup"
export default {
    data() {
        return {
            tabs: ['全部', '内容', '壁纸'],
            tabIndex: 0,
            isShow: false,
            reviewImg: ""
        };
    },
    components: {
        Popup
    },
    created() {
        this.$store.dispatch("aquireCollection", this.$EventBus)
    },
    computed: {
        ...mapGetters(['getCollection'])
    },
    methods: {
        changeTab(index) {
            this.tabIndex = index
        },
        // 取消收藏
        cannelUserCollection(type, item) {
            this.$store.dispatch("cannelUserCollection", { type, item, $EventBus: this.$EventBus })
            // this.$request({
            //     method: "delete",
            //     url: this.$requestPath.delCollect,
            //     data: {
            //         relationId: type == 'wallpaper' ? item.wallpaperId : item.tidingsId,
            //         type: type == 'wallpaper' ? 1 : 2
            //     }
            // }).then(res => {
            //     console.log("取消收藏", res)
            //     if (res.code == 0) {
            //         this.$EventBus.$emit("toast", {
            //             type: "success",
            //             message: "取消收藏成功",
            //         });
            //         // 本地删除收藏
            //         this.$store.commit("delCollect", { type, id: type == 'wallpaper' ? item.wallpaperId : item.tidingsId })
            //     }
            //     // else {
            //     //     this.$EventBus.$emit("toast", {
            //     //         type: "error",
            //     //         message: res.msg,
            //     //     });
            //     // }
            // }).catch(error => {
            //     console.log("获取用户信息", error)
            // })
        },
        // 壁纸下载
        download(url) {
            this.canvasDownload(url)
        },
        // 关闭弹窗
        closeSearch() {
            this.isShow = false
        },
        // 图片预览
        review(url) {
            this.isShow = true
            this.reviewImg = url
        }
    },
};
</script>

<style lang='scss' scoped>
.border_box {
    border: 0.0625rem solid var(--border-base);
}

@media screen and (max-width: 640px) {
    .img_box {
        width: 4rem;
    }
    .base_right {
        width: calc(100% - 5rem);
        font-size: 0.75rem;
        img {
            width: 0.375rem !important;
        }
        .info_base {
            margin: 0.3125rem 0;
        }
    }
    .base_info {
        padding: 0.625rem 0.25rem;
    }
    .table_title {
        padding: 0.5rem 0;
    }
    .conent_box {
        padding: 0;
        border: none !important;
    }
    .text_left {
        width: 3.125rem;
        display: inline-block;
        text-align: left;
    }
    li {
        font-size: 0.75rem;
    }
    li:not(:last-child) {
        margin-bottom: 0.25rem;
    }
    .pc_show {
        display: none;
    }
    .pc_flex_show {
        display: none;
    }
}
@media screen and (max-width: 900px) {
    .pc_flex_show {
        display: none;
    }
    .box_margin {
        margin-top: 0.625rem;
    }
    .icon_box {
        bottom: 0.25rem;
        right: 0.25rem;
        img {
            width: 0.75rem;
            margin-left: 0.3125rem;
        }
    }
    .phone_flex_show {
        display: flex;
    }

    .title {
        font-size: 1.25rem;
    }
}
@media screen and (min-width: 640px) {
    .pc_show {
        display: block;
    }
    .pc_flex_show {
        display: flex;
    }
    li {
        list-style-type: disc !important;
    }
    li:not(:last-child) {
        margin-bottom: 0.625rem;
    }
    .table_title {
        padding: 0.5rem 0;
    }
    .conent_box {
        padding: 1rem;
    }
    .img_box {
        width: 6.25rem;
    }
    .base_right {
        width: calc(100% - 8rem);
    }
    .info_base {
        margin: 1rem 0;
    }
    .text_left {
        width: 3.75rem;
        display: inline-block;
        text-align: left;
    }
}
@media screen and (min-width: 900px) {
    .pc_flex_show {
        display: flex;
    }
    .btn {
        width: 49%;
        margin: 0 !important;
    }
    .phone_flex_show {
        display: none;
    }

    .title {
        font-size: 1.875rem;
    }
}
</style>